// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lobby-section-swiper-navigation_jSlnL{display:flex;margin-left:10px}.lobby-section-swiper-navigation__button_AqCcx:last-child{margin-left:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-section-swiper-navigation": `lobby-section-swiper-navigation_jSlnL`,
	"lobby-section-swiper-navigation__button": `lobby-section-swiper-navigation__button_AqCcx`
};
export default ___CSS_LOADER_EXPORT___;
