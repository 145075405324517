// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .lobby-promotion-item_SM78r{position:relative;display:block;height:240px;overflow:hidden;isolation:isolate;border-radius:5px}html.app__layout--desktop .lobby-promotion-item_SM78r:hover .lobby-promotion-item__image-container_id9sL{transform:scale(1.05)}html.app__layout--desktop .lobby-promotion-item__image-container_id9sL{height:100%;transition:transform .5s ease}html.app__layout--desktop .lobby-promotion-item__title-block_ODH3y{z-index:5;position:absolute;bottom:0;width:100%;-webkit-text-decoration:none;text-decoration:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-promotion-item": `lobby-promotion-item_SM78r`,
	"lobby-promotion-item__image-container": `lobby-promotion-item__image-container_id9sL`,
	"lobby-promotion-item__title-block": `lobby-promotion-item__title-block_ODH3y`
};
export default ___CSS_LOADER_EXPORT___;
