import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue";
import { LobbyGridType, LobbyItemType, LobbyItemWidget, LobbyTitleType } from '@leon-hub/api-sdk';
import LobbySectionContainer from 'web/src/modules/lobby/components/LobbySectionContainer/LobbySectionContainer.vue';
import { useLobbyBlock, useLobbySyncState } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyBlock',
    props: {
        testEl: {
            default: 'egs-lobby'
        },
        isSport: {
            type: Boolean
        },
        isModal: {
            type: Boolean
        },
        shouldReplaceGameRoutes: {
            type: Boolean
        },
        preventItemsFetch: {
            type: Boolean
        },
        isAllTab: {
            type: Boolean
        },
        groupId: {},
        categoryId: {},
        lobbyType: {},
        groupUrl: {},
        excludeGameIds: {
            default: ()=>[]
        },
        skeletons: {
            default: ()=>[
                    {
                        typeId: LobbyItemType.GAMES_CATEGORY,
                        gridType: LobbyGridType.DEFAULT,
                        widgetId: LobbyItemWidget.SWIPE_LIST,
                        titleType: LobbyTitleType.DEFAULT,
                        swiperRowsNumber: 1,
                        isSwiperBlock: false
                    }
                ]
        },
        isHeaderWithHorizontalMargin: {
            type: Boolean
        },
        isFiltersAllowed: {
            type: Boolean
        },
        noBackground: {
            type: Boolean
        },
        isCustomHomepage: {
            type: Boolean
        },
        isFullHeight: {
            type: Boolean
        },
        groupBlock: {}
    },
    emits: [
        "game-click",
        "delete-items"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isLoadingTransition, isContentLoading, skeletonItems, lobbySectionProps, lobbyItems, onSwiperScroll, onSectionScroll, onTopWinnersFilterClick, onClickLobbyItem, onActionClick, emitDeleteItems } = useLobbyBlock(props, emit);
        useLobbySyncState(lobbyItems);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['lobby-block-wrapper']]: _ctx.isFullHeight
                })
            }, [
                _createVNode(_Transition, {
                    name: "fade",
                    "enter-active-class": _ctx.$style['fade-enter-active'],
                    "enter-from-class": _ctx.$style['fade-enter-from'],
                    "leave-active-class": _ctx.$style['fade-leave-active'],
                    "leave-to-class": _ctx.$style['fade-leave-to']
                }, {
                    default: _withCtx(()=>[
                            _unref(isLoadingTransition) ? _createCommentVNode("", true) : _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass({
                                    [_ctx.$style['lobby-block']]: true,
                                    [_ctx.$style['lobby-block--modal']]: _ctx.isModal
                                })
                            }, [
                                _unref(isContentLoading) ? (_openBlock(true), _createElementBlock(_Fragment, {
                                    key: 0
                                }, _renderList(_unref(skeletonItems), (lobbySection, index)=>(_openBlock(), _createBlock(LobbySectionContainer, _mergeProps({
                                        key: index,
                                        ref_for: true
                                    }, lobbySection), null, 16))), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
                                    key: 1
                                }, _renderList(_unref(lobbySectionProps), (lobbySection, index)=>(_openBlock(), _createBlock(LobbySectionContainer, _mergeProps({
                                        key: index,
                                        ref_for: true,
                                        ref: "container"
                                    }, lobbySection, {
                                        onSwiperScroll: ($event)=>_unref(onSwiperScroll)($event, lobbySection.lobbyItem),
                                        onSectionScroll: ($event)=>_unref(onSectionScroll)($event, lobbySection.lobbyItem),
                                        onTopWinnersFilterClick: ($event)=>_unref(onTopWinnersFilterClick)($event, lobbySection.lobbyItem),
                                        onClickLobbyItem: ($event)=>_unref(onClickLobbyItem)($event, lobbySection.lobbyItem),
                                        onActionClick: ($event)=>_unref(onActionClick)(lobbySection.lobbyItem),
                                        onDeleteItems: _unref(emitDeleteItems)
                                    }), null, 16, [
                                        "onSwiperScroll",
                                        "onSectionScroll",
                                        "onTopWinnersFilterClick",
                                        "onClickLobbyItem",
                                        "onActionClick",
                                        "onDeleteItems"
                                    ]))), 128))
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: _ctx.testEl,
                                        loading: _unref(isContentLoading)
                                    }
                                ]
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "enter-active-class",
                    "enter-from-class",
                    "leave-active-class",
                    "leave-to-class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbyBlock'
                ]
            ]);
        };
    }
});
