// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .lobby-grid-section_EzAAq{display:flex;flex-direction:column;overflow:hidden}html.app__layout--desktop .lobby-grid-section__grid_nh8Y9{margin:8px}@media(min-width:768px){html.app__layout--desktop .lobby-grid-section__grid_nh8Y9{margin:8px 0}}@media(min-width:1280px){html.app__layout--desktop .lobby-grid-section__grid_nh8Y9{margin:8px 0}}html.app__layout--desktop .lobby-grid-section__tabs_ZFSVg{margin:-8px 0 8px}html.app__layout--desktop .lobby-grid-section__tabs--skeleton_TLIfr{display:flex;align-items:center;height:44px}html.app__layout--desktop .lobby-grid-section__skeleton-tab_n22QJ{position:relative;display:flex;align-items:center;width:40px;max-width:100%;height:20px;margin:0 16px}html.app__layout--desktop .lobby-grid-section__skeleton-tab_n22QJ:before{width:100%;height:7px;content:"";background-color:var(--TextDefault);border-radius:20px;opacity:.2}html.app__layout--desktop .lobby-grid-section__no-games_hBvd1{margin-top:-8px}html.app__layout--desktop .lobby-grid-section__no-games--filter_Sjz09{margin-top:16px}html.app__layout--desktop .lobby-grid-section--widget-SQUARE_ROWS_LIST_HLyVw{padding-bottom:16px}html.app__layout--desktop .lobby-grid-section--item-type-BANNERS_Iq0PJ{margin-bottom:-16px}html.app__layout--desktop .lobby-grid-section--item-type-BANNERS_Iq0PJ .lobby-grid-section__grid_nh8Y9{margin:0}html.app__layout--desktop .lobby-grid-section__more_oIeja{display:flex;flex-direction:column;align-items:center;justify-content:center;margin-top:16px}@media(min-width:1280px){html.app__layout--desktop .lobby-grid-section__more_oIeja{margin-top:16px}}html.app__layout--desktop .lobby-grid-section__more-progress-container_uv8I0{display:flex;flex-direction:column;gap:8px;width:240px;margin:16px 0}html.app__layout--desktop .lobby-grid-section__more-progress-text_tsMAu{font-size:13px;font-weight:400;line-height:20px;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-grid-section": `lobby-grid-section_EzAAq`,
	"lobby-grid-section__grid": `lobby-grid-section__grid_nh8Y9`,
	"lobby-grid-section__tabs": `lobby-grid-section__tabs_ZFSVg`,
	"lobby-grid-section__tabs--skeleton": `lobby-grid-section__tabs--skeleton_TLIfr`,
	"lobby-grid-section__skeleton-tab": `lobby-grid-section__skeleton-tab_n22QJ`,
	"lobby-grid-section__no-games": `lobby-grid-section__no-games_hBvd1`,
	"lobby-grid-section__no-games--filter": `lobby-grid-section__no-games--filter_Sjz09`,
	"lobby-grid-section--widget-SQUARE_ROWS_LIST": `lobby-grid-section--widget-SQUARE_ROWS_LIST_HLyVw`,
	"lobby-grid-section--item-type-BANNERS": `lobby-grid-section--item-type-BANNERS_Iq0PJ`,
	"lobby-grid-section__more": `lobby-grid-section__more_oIeja`,
	"lobby-grid-section__more-progress-container": `lobby-grid-section__more-progress-container_uv8I0`,
	"lobby-grid-section__more-progress-text": `lobby-grid-section__more-progress-text_tsMAu`
};
export default ___CSS_LOADER_EXPORT___;
