// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lobby-egs-games-item-buttons-hover_FusvV{position:absolute;top:0;right:0;bottom:0;left:0;display:flex;align-items:center;justify-content:center;opacity:0;transition:opacity .2s ease}.lobby-egs-games-item-buttons-hover__btn_do3Bv{z-index:1}.lobby-egs-games-item-buttons-hover__btn--demo_xfzVI{position:absolute;bottom:12px}.lobby-egs-games-item-buttons-hover_FusvV:after{position:absolute;top:0;right:0;bottom:0;left:0;content:"";background-color:#0009;opacity:0;transition:opacity .3s ease}.lobby-egs-games-item-buttons-hover_FusvV:hover,.lobby-egs-games-item-buttons-hover_FusvV:hover:after{opacity:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-egs-games-item-buttons-hover": `lobby-egs-games-item-buttons-hover_FusvV`,
	"lobby-egs-games-item-buttons-hover__btn": `lobby-egs-games-item-buttons-hover__btn_do3Bv`,
	"lobby-egs-games-item-buttons-hover__btn--demo": `lobby-egs-games-item-buttons-hover__btn--demo_xfzVI`
};
export default ___CSS_LOADER_EXPORT___;
