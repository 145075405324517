import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
import { toRef } from 'vue';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { useBannerLocation, useBannerTexts } from 'web/src/modules/banners/components/composables';
import VSkeletonImage from 'web/src/components/Skeleton/VSkeletonImage/VSkeletonImage.vue';
import { ObjectFitOption, ObjectPosition } from 'web/src/components/Image/VImage/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LobbyBannerItem',
    props: {
        item: {}
    },
    setup (__props) {
        const props = __props;
        const bannerRef = toRef(props, 'item');
        const { buttonText } = useBannerTexts(bannerRef);
        const { href, onClick } = useBannerLocation(bannerRef);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['lobby-banner']]: true
                })
            }, [
                _ctx.item ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _createVNode(VImage, _mergeProps(_ctx.item.image, {
                        "object-fit": _unref(ObjectFitOption).COVER,
                        "object-position": _unref(ObjectPosition).LEFT,
                        class: _ctx.$style['lobby-banner__image']
                    }), null, 16, [
                        "object-fit",
                        "object-position",
                        "class"
                    ]),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['lobby-banner__informer'])
                    }, [
                        _unref(buttonText) ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            class: _normalizeClass({
                                [_ctx.$style['lobby-banner__btn']]: true
                            }),
                            href: _unref(href),
                            onClick: _cache[0] || (_cache[0] = //@ts-ignore
                            function() {
                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                    args[_key] = arguments[_key];
                                }
                                return _unref(onClick) && _unref(onClick)(...args);
                            })
                        }, _toDisplayString(_unref(buttonText)), 11, _hoisted_1)) : _createCommentVNode("", true)
                    ], 2)
                ], 64)) : (_openBlock(), _createBlock(VSkeletonImage, {
                    key: 1
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'LobbyBannerItem'
                ]
            ]);
        };
    }
});
