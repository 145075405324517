// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lobby-block-wrapper_SogYi{min-height:100vh}.lobby-block_d30pH{display:flex;flex-direction:column;gap:16px;padding-bottom:16px}@media(min-width:1280px){.lobby-block_d30pH{gap:16px}}.lobby-block--modal_qu7A5{gap:16px}@media(min-width:768px){.lobby-block--modal_qu7A5{gap:16px}}.fade-enter-active_pFSj_,.fade-leave-active_pUT5T{transition:opacity .1s ease}.fade-enter-from_cQSCQ,.fade-leave-to_UTd_P{opacity:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-block-wrapper": `lobby-block-wrapper_SogYi`,
	"lobby-block": `lobby-block_d30pH`,
	"lobby-block--modal": `lobby-block--modal_qu7A5`,
	"fade-enter-active": `fade-enter-active_pFSj_`,
	"fade-leave-active": `fade-leave-active_pUT5T`,
	"fade-enter-from": `fade-enter-from_cQSCQ`,
	"fade-leave-to": `fade-leave-to_UTd_P`
};
export default ___CSS_LOADER_EXPORT___;
