// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lobby-egs-games-item_kbrpX{display:flex;flex-direction:column;gap:8px;width:100%;-webkit-text-decoration:none;text-decoration:none}.lobby-egs-games-item__name-wrapper_L1Llu{position:relative;height:12px}@media(min-width:768px){.lobby-egs-games-item__name-wrapper_L1Llu{height:16px}}.lobby-egs-games-item__name_lOam0{font-size:12px;font-weight:500;line-height:12px;letter-spacing:normal;position:absolute;width:100%;overflow:hidden;color:var(--TextDefault);text-align:center;text-overflow:ellipsis;white-space:nowrap}@media(min-width:768px){.lobby-egs-games-item__name_lOam0{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px}}.lobby-egs-games-item__name--skeleton_PPO_l{position:relative;display:flex;align-items:center;width:64px;max-width:100%;height:12px;margin:auto}.lobby-egs-games-item__name--skeleton_PPO_l:before{width:100%;height:7px;content:"";background-color:var(--TextDefault);border-radius:20px;opacity:.2}@media(min-width:768px){.lobby-egs-games-item__name--skeleton_PPO_l{position:relative;display:flex;align-items:center;width:64px;max-width:100%;height:16px}.lobby-egs-games-item__name--skeleton_PPO_l:before{width:100%;height:8px;content:"";background-color:var(--TextDefault);border-radius:20px;opacity:.2}}.lobby-egs-games-item_kbrpX:hover .lobby-egs-games-item__name_lOam0{color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-egs-games-item": `lobby-egs-games-item_kbrpX`,
	"lobby-egs-games-item__name-wrapper": `lobby-egs-games-item__name-wrapper_L1Llu`,
	"lobby-egs-games-item__name": `lobby-egs-games-item__name_lOam0`,
	"lobby-egs-games-item__name--skeleton": `lobby-egs-games-item__name--skeleton_PPO_l`
};
export default ___CSS_LOADER_EXPORT___;
