// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .lobby-all-top-winners_zO2CB{display:flex;flex-direction:column}html.app__layout--desktop .lobby-all-top-winners__switcher_xgYaF{min-width:343px;margin-left:auto}html.app__layout--desktop .lobby-all-top-winners__table-wrapper_MD4Fj{width:100%}html.app__layout--desktop .lobby-all-top-winners__btn_s8dhc{margin-top:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-all-top-winners": `lobby-all-top-winners_zO2CB`,
	"lobby-all-top-winners__switcher": `lobby-all-top-winners__switcher_xgYaF`,
	"lobby-all-top-winners__table-wrapper": `lobby-all-top-winners__table-wrapper_MD4Fj`,
	"lobby-all-top-winners__btn": `lobby-all-top-winners__btn_s8dhc`
};
export default ___CSS_LOADER_EXPORT___;
