// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lobby-swiper-section_OVSkg{padding:0;border:none;border-radius:0}@media(min-width:1280px){.lobby-swiper-section_OVSkg{padding:0}}.lobby-swiper-section--no-background_nsz1S{padding:0;margin-right:-16px;margin-left:-16px;background:#0000;border:none;border-radius:0}@media(min-width:768px){.lobby-swiper-section--no-background_nsz1S{margin-right:0;margin-left:0}}@media(min-width:1280px){.lobby-swiper-section--no-background_nsz1S{padding:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-swiper-section": `lobby-swiper-section_OVSkg`,
	"lobby-swiper-section--no-background": `lobby-swiper-section--no-background_nsz1S`
};
export default ___CSS_LOADER_EXPORT___;
