// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .lobby-promotion-title-panel_GXJlc{height:96px;padding:12px 16px 16px;color:var(--TextDefault)}html.app__layout--desktop .lobby-promotion-title-panel__title_W8Up5{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;margin:0 0 8px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}html.app__layout--desktop .lobby-promotion-title-panel__description_mNLen{font-size:13px;font-weight:400;line-height:20px;letter-spacing:normal}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-promotion-title-panel": `lobby-promotion-title-panel_GXJlc`,
	"lobby-promotion-title-panel__title": `lobby-promotion-title-panel__title_W8Up5`,
	"lobby-promotion-title-panel__description": `lobby-promotion-title-panel__description_mNLen`
};
export default ___CSS_LOADER_EXPORT___;
