// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .lobby-banner_fjGD8{position:relative;width:100%;min-width:300px;height:264px;overflow:hidden;border-radius:var(--BorderRadius);box-shadow:0 8px 16px var(--ModalShadow)}html.app__layout--desktop .lobby-banner__image_UFAj4{position:absolute;top:0;right:0;bottom:0;width:100%;max-width:100%;height:100%}html.app__layout--desktop .lobby-banner__informer_mdFNY{position:absolute;bottom:40px;left:32px;max-width:364px}html.app__layout--desktop .lobby-banner__btn_ABWez{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;display:inline-block;height:44px;padding:14px 16px;color:var(--DLayer1);-webkit-text-decoration:none;text-decoration:none;text-transform:uppercase;background-color:var(--DTextDefault);border-radius:5px;transition:box-shadow .2s ease;box-shadow:0 2px 6px 0 #00000080,0 1px 2px 0 #0000004d}html.app__layout--desktop .lobby-banner__btn_ABWez:hover{box-shadow:0 0 12px var(--White)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-banner": `lobby-banner_fjGD8`,
	"lobby-banner__image": `lobby-banner__image_UFAj4`,
	"lobby-banner__informer": `lobby-banner__informer_mdFNY`,
	"lobby-banner__btn": `lobby-banner__btn_ABWez`
};
export default ___CSS_LOADER_EXPORT___;
